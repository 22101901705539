.dropdown-btn {
  background: none;
  border: none;
  padding: 8px 12px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-size: 14px;
  align-items: center;
  font-weight: 500;
}
.dropdown-btn:hover {
  background-color: rgb(232, 237, 241);
}
.dropdown-btn svg {
  margin-left: 7px;
  width: 16px;
  height: 16px;
}
.main-dropdown li.footer-drop{
  padding:0px 16px;

}
li.footer-drop:hover{
  background: none;

}
.main-dropdown li {
  font-size: 14px;
  padding-left: 35px;
  position: relative;
}
.main-dropdown li svg {
  font-size: 16px;
  width: 16px;
}
.szh-menu-container .szh-menu {
  top: 0px !important;
  box-shadow: rgb(37 42 49 / 16%) 0px 4px 8px 0px,
    rgb(37 42 49 / 24%) 0px 8px 24px 0px;
}
.passengers {
  display: flex;
  align-items: center;
}
.passengers svg {
  height: 24px;
  width: 24px;
  fill: rgb(37, 42, 49);
}
.passengers span {
  color: var(--black-color);
  font-size: 14px;
  margin: 0px 12px;
}
li.passenger-container {
  padding-left: 16px;
  min-width: 230px;
  display: block;
}

.item-flex {
  display: flex;
  align-items: center;
  width: 100%;
}
.passenger-container .item-flex p {
  line-height: 20px;
  margin-right: 8px;
}
.passenger-container .item-flex svg {
  fill: rgb(79, 94, 113);
  width: 16px;
  height: 16px;
}
.pass-users {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pass-users span {
  font-size: 12px;
  color: rgb(79, 94, 113);
  line-height: 16px;
  text-align: left;
  margin: 0px;
}
.dropDown-item svg {
  position: absolute;
  left: 10px;
}
.item-flex .btn-group {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.item-flex.btn-group button svg {
  margin: 0px;
  width: 16px;
  height: 16px;
  color: rgb(37, 42, 49);
}
.btn-group button:hover {
  background-color: rgb(232, 237, 241);
}
.list-item-text span {
  font-size: 14px;
}
.btn-group span {
  margin: 0px 12px;
  font-weight: 700;
}
.passenger-container h2 {
  font-size: 14px;
  color: var(--black-color);
  margin-bottom: 12px;
  font-weight: 500;
}
