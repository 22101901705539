.input-group-container {
  position: relative;
}
span.error-msg {
  position: absolute;
  font-size: 12px;
  bottom: -19px;
  color: rgb(210 28 28);
}
.input-wapper.error {
  box-shadow: rgb(210 28 28) 0px 0px 0px 1px;
}
.input-wapper {
  display: flex;
  flex: 1 1 0%;
  border-radius: 3px !important;
  box-shadow: rgb(186 199 213) 0px 0px 0px 1px;
  cursor: pointer;
  transition: all 0.15s ease-in-out 0s;
  user-select: none;
  margin-bottom: 8px;
  overflow: hidden;
  height: 44px !important;
  align-items: center;
  background-color: rgb(255, 255, 255);
}

.input-group-container .input-wapper.textarea {
  height: 100px !important;
}
.input-group-container .input-wapper textarea {
  height: 100%;
}
.input-wapper input,
.input-wapper textarea,
.input-wapper select {
  border-radius: 3px;
  box-shadow: rgb(186 199 213) 0px 0;
  width: 100%;
  padding: 0px 8px;
  color: rgb(37, 42, 49);
  font-weight: 500;
  white-space: nowrap;
  border: 0px;
  cursor: text;
  height: 36px;
  background-color: rgb(255, 255, 255);
}
.input-wapper select {
  padding: 0px 16px;
}
.input-wapper select option {
  font-size: 1rem;
  padding: 16px;
  line-height: 1.25rem;
}
.input-wapper input::placeholder {
  font-weight: 400;
}
.result-search-wrapper {
  max-height: 300px;
  overflow: auto;
}
.input-wapper .search-field-fake-input {
  width: 100%;
  padding: 0px 8px;
  color: rgb(37, 42, 49);
  font-weight: 500;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
}
.input-wapper input:focus {
  border-color: var(--primary-color);
  box-shadow: none;
  outline: 0;
}

.input-wapper .search-label {
  margin: 0px 4px 0px 12px;
  color: rgb(79, 94, 113);
  line-height: 44px;
  cursor: pointer;
  white-space: nowrap;
}

.mandatory-sign {
  color: #ff3737;
  margin-right: 4px;
}
.password-icon {
}
