.card-wrapper {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  outline: 0px;
  box-shadow:
    rgb(37 42 49 / 16%) 0px 0px 2px 0px,
    rgb(37 42 49 / 12%) 0px 1px 4px 0px;
  transition: box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  display: block;
  position: relative;
  margin-bottom: 16px;
}
.circle-card {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgb(245, 247, 249);
}
.card-wrapper.active .circle-card {
  box-shadow:
    var(--secondary-color) 0px 0px 2px 0px,
    var(--secondary-color) 0px 1px 4px 0px;
}
.card-wrapper.active .circle-card:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 41px;
  background-color: rgb(245, 247, 249);
}

.card-wrapper.active .circle-card:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 12px;
  background-color: rgb(245, 247, 249);
}

.circle-card.one:after {
  left: -10px;
  top: -3px;
}

.circle-card.one:before {
  left: 9px;
  top: -2px;
}

.circle-card.two:after {
  right: -10px;
  top: -3px;
}

.circle-card.two:before {
  right: 9px;
  top: -2px;
}

.circle-card.three:after {
  right: -10px;
  bottom: -3px;
}

.circle-card.three:before {
  left: -5px;
  bottom: -4px;
}

.circle-card.four:after {
  left: -10px;
  bottom: -4px;
}

.circle-card.four:before {
  left: 9px;
  bottom: -5px;
}

.circle-card.one {
  top: -12px;
  left: -12px;
}
.circle-card.two {
  top: -12px;
  right: -12px;
}
.circle-card.three {
  bottom: -12px;
  right: -12px;
}
.circle-card.four {
  bottom: -12px;
  left: -12px;
}

/* .circle-card {
  position: relative;
  top: 20px;
  height: 80px;
  width: 80px;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0 0 10px #000000;
  background-color: white;
}

.circle-card:before {
  position: absolute;
  content: "";
  width: 150%;
  height: 50%;
  left: -25%;
  top: -10px;
  background: #63b0f2;
}

.circle-card:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: white;
  border-radius: 50%;
} */
.card-wrapper:hover {
  box-shadow:
    rgb(37 42 49 / 30%) 0px 0px 2px 0px,
    rgb(37 42 49 / 12%) 0px 1px 4px 0px;
}
.result-card-main {
  width: 100%;
}
.card-wrapper .result-card-price {
  padding: 16px;
  flex: 1 1 0%;
  display: block;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  position: relative;
}
.card-wrapper .result-card-price button {
  position: relative;
  font-size: 12px;
}
.card-wrapper .result-card-price p {
  font-size: 28px;
  margin-bottom: 12px;
}
.result-card-time {
  margin-bottom: 4px;
  font-weight: 500;
}

.result-card-place {
  width: calc(100% - 19px);
  white-space: nowrap;
  display: flex;
  flex-direction: column;
}
.result-card-place span {
  margin-left: 8px;
  font-weight: 500;
  white-space: pre-wrap;
}
.result-card-place div {
  font-size: 12px;
  margin: 0px;
}
.result-card-content,
.results-card-badges {
  padding: 16px;
}
.results-card-badges {
  border-style: solid;
  border-color: rgb(239, 242, 245);
  border-image: initial;
  border-width: 1px 0px;
  margin-top: 16px;
}
.badges {
  margin: 8px 0px 4px;
  display: inline-flex;
  /* flex-wrap: wrap; */
  align-items: center;
}
.results-card-badges {
  display: none;
}
.badges button {
  margin-left: auto;
  font-size: 10px;
  padding: 0px 8px;
}
.card-wrapper .result-card-price .sharing-icon {
  position: absolute;
  top: 8px;
  right: 18px;
}
.card-wrapper.active {
  box-shadow:
    var(--secondary-color) 0px 0px 2px 0px,
    var(--secondary-color) 0px 1px 4px 0px;
}
.badges button:nth-child(even) {
  margin: 0px 4px;
}
.result-card-slice-title {
  display: flex;
}
.fare-des {
  margin-bottom: 16px;
  padding-bottom: 18px;
  border-bottom: 1px solid #aaa;
}
.arrow-wrapper {
  position: relative;
  display: flex;
  flex-shrink: 0;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 8px;
}
.MuiButton-code.MuiButton-codePrimary {
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-transform: initial;
  min-width: 200px;
  border-radius: 10px;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  padding: 0.875rem 50px 0.875rem 1rem;
  scroll-margin-top: calc(var(--MuiDocs-header-height) + 32px);
  color: rgb(45, 56, 67);
  border: 1px solid rgb(205, 210, 215);
  background-color: rgb(243, 246, 249);
  font-family: Consolas, Menlo, Monaco, "Andale Mono", "Ubuntu Mono", monospace;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.5;
  letter-spacing: 0px;
  -webkit-font-smoothing: subpixel-antialiased;
  display: inline-block;
  -webkit-box-pack: start;
  justify-content: start;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}
svg.normal-size {
  width: 16px;
  height: 16px;
  fill: rgb(37, 42, 49);
  margin-right: 4px;
}

.MuiButton-code.MuiButton-codePrimary:hover {
  border: 1px solid var(--primary-color);
  box-shadow: none;
  background-color: rgb(255, 241, 225);
}
.MuiButton-code.MuiButton-codePrimary:hover .MuiButton-endIcon svg {
  color: var(--primary-color);
  fill: var(--primary-color);
}

.MuiButton-code.MuiButton-codePrimary .MuiButton-endIcon {
  display: inline-block;
  position: absolute;
  right: 0px;
  margin-right: 10px;
  color: rgb(62, 80, 96);
}
.arrow-wrapper .arrow {
  position: absolute;
  left: calc(50% - 1px);
  width: 2px;
  height: 50%;
  background: rgb(232, 237, 241);
  top: 50%;
}
.arrow-wrapper .arrow.bottom {
  bottom: 50%;
  top: auto;
}
.arrow-wrapper .trip-icon {
  position: relative;
  z-index: 1;
  background: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(255, 255, 255);
}
.arrow-wrapper .trip-icon svg {
  fill: var(--black-color);
}
.arrow-wrapper svg {
  width: 16px;
  height: 16px;
  fill: rgb(79, 94, 113);
  z-index: 1;
}
.layover-text {
  position: absolute;
  top: -8px;
  left: 50%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  transform: translateX(-50%);
  padding: 0px 12px;
  background: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}
.sector-layover {
  position: relative;
  margin-top: 7px;
}
.arrival-date {
  margin-top: 4px;
  font-weight: 500;
  margin-bottom: 0px;
}
.show-mobile {
  display: block;
}
.card-wrapper .result-card-price button.show-mobile {
  margin-right: 8px;
}

.card-wrapper .result-card-price button.primary-color {
  width: 100%;
}

@media (max-width: 768px) {
  .badges {
    flex-wrap: wrap;
  }
}

@media (min-width: 576px) {
  .card-wrapper .result-card-price button.primary-color {
    width: calc(100% - 32px);
    margin: auto;
  }
  .result-card-main {
    width: 65%;
    border-right: 4px dashed rgb(239, 242, 245);
    border-image: url("assets/images/dashed.svg") 2 round;
  }
  .show-mobile {
    display: none;
  }

  .results-card-badges {
    display: block;
  }
  .card-wrapper,
  .card-wrapper .result-card-price {
    display: flex;
  }
  .btn-footer-wrapper {
    width: 100%;
    position: absolute;
    bottom: 16px;
  }
  .result-card-place div {
    font-size: 14px;
    margin-left: 4px;
  }
  .result-card-place {
    flex-direction: row;
  }
  .card-wrapper .result-card-price button {
    font-size: 14px;
  }
}
/* NEw */

[class^="ribbon-"] {
  position: relative;
  margin-bottom: 80px;
}
[class^="ribbon-"]:before,
[class^="ribbon-"]:after {
  content: "";
  position: absolute;
}

.ribbon-2 {
  width: 150px;
  height: 38px;
  background: var(--secondary-color);
  /* left: -8px; */
  position: absolute;
  right: -8px;
  top: 45px;
  transform: rotate(180deg);
}
.ribbon-2:before {
  height: 0;
  width: 0;
  border-bottom: 8px solid #14455b;
  border-left: 8px solid transparent;
  top: -8px;
}
.ribbon-2:after {
  background: inherit;
  border-radius: 5px;
  display: block;
  content: "";
  height: 30px;
  position: absolute;
  right: -29px;
  top: -2px;
  transform-origin: top left;
  transform: rotate(45deg);
  width: 29px;
  z-index: -1;
}

.ribbon-2 span {
  font-size: 20px;
  /* font-weight: bold; */
  line-height: 13px;
  position: absolute;
  z-index: 14;
  transform: rotate(-180deg);
  top: 12px;
  left: 5px;
  width: 100%;
  text-align: center;
  color: #fff;
}

@media (max-width: 768px) {
  .ribbon-2 {
    width: 110px;
    height: 38px;
  }
  .ribbon-2:before {
    height: 0;
    width: 0;
    border-bottom: 8px solid #14455b;
    border-left: 8px solid transparent;
    top: -8px;
  }
  .ribbon-2:after {
    background: inherit;
    border-radius: 5px;
    display: block;
    content: "";
    height: 30px;
    position: absolute;
    right: -29px;
    top: -2px;
    transform-origin: top left;
    transform: rotate(45deg);
    width: 29px;
    z-index: -1;
  }

  .ribbon-2 span {
    font-size: 15px;
  }
}
