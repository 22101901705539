.card-container {
  overflow: hidden;
  background: rgb(255, 255, 255);
  border-radius: 6px;
  box-shadow: rgb(37 42 49 / 16%) 0px 0px 2px 0px,
    rgb(37 42 49 / 12%) 0px 1px 4px 0px;
  transition: box-shadow 0.15s ease-out 0s;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 105px;
  margin-bottom: 24px;
}
.card-container:hover {
  box-shadow: rgb(37 42 49 / 16%) 0px 4px 8px 0px,
    rgb(37 42 49 / 24%) 0px 8px 24px 0px;
  transform: translateZ(0.1px);
}
.card-container img {
  width: 158px;
  height: 100%;
}
.card-container svg {
  width: 16px;
}

.card-container h1 {
  font-size: 16px;
  margin: 0px;
  margin-left: 16px;
  line-height: 24px;
  color: rgb(37, 42, 49);
}
