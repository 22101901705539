.banner-bg {
  position: relative;
  background: #fff;
  padding-top: 16px;
}
.home-image img {
  position: absolute;
  top: 0px;
  left: 0px;
  display: initial;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 0% 100%;
  transition: opacity 0.3s ease-out 0s;
  pointer-events: none;
}

.home-image {
  position: absolute;
  /* background-image: url("assets/images/hero-largeDesktop.jpg"); */
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.banner .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  z-index: 9;
}
.banner .container .card {
  border-radius: 10px;
  padding: 24px;
  box-shadow: rgb(37 42 49 / 30%) 0px 4px 12px 0px;
  max-width: 1120px;
}
.block-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* height: 44px; */
}

.banner .container h1 {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: 0.4px;
  color: #fff;
}

.banner .container p.banner-sub-title {
  text-shadow: rgb(0 0 0 / 5%) 0px 4px 10px;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  letter-spacing: 0.4px;
}

.banner .container .dropdown {
  display: inline-block;
}
.banner .container .dropdown a {
  color: rgb(37 42 49);
}

.card-above .tab {
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  position: relative;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 40%; */
}
.card-above .tab.disabled {
  pointer-events: none;
}

.card-above .tab.active,
.card-above .tab:hover {
  color: var(--primary-color);
}
.card-above .tab svg {
  margin-right: 8px;
}
.card-above .tab:first-child svg {
  transform: rotate(45deg);
}
.card-above .tab.active svg,
.card-above .tab:hover svg {
  fill: var(--primary-color);
}
.card-above .tab:hover:after,
.card-above .tab.active:after {
  content: "";
  height: 2px;
  border-radius: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: var(--primary-color);
  bottom: -1px;
}

.banner .container .card.card-above {
  padding: 10px 10px 0px 10px;
  max-width: 500px;
  margin: 0px auto;
  margin-bottom: -10px;
  z-index: 10;
}
.search-btn.btn {
  background-color: var(--primary-color);
  border-radius: 3px;
  color: #fff;
  height: 44px;
  white-space: nowrap;
}
.block-group-item .switch-icon {
  position: absolute;
  right: -19px;
  z-index: 9;
  border-radius: 10px;
  display: flex;
  width: 26px;
  height: 26px;
  top: 8px;
  left: "97%";

  background: #fdb99d;
  color: rgb(37, 42, 49);
  background-color: rgb(239, 242, 245);
  border-radius: 50%;
  box-shadow:
    rgb(0 0 0 / 16%) 0px 1px 4px 0px,
    rgb(0 0 0 / 5%) 0px 1px 5px 0px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.block-group-item .switch-icon svg {
  margin: 0px;
  width: 16px;
  height: 16px;
}
.block-group-item .switch-icon:hover {
  transform: scale(1.2, 1.2);
  background: var(--primary-color);
  color: rgb(255, 255, 255);
}

.block-group-item .switch-icon:hover svg {
  fill: rgb(255, 255, 255);
}
.card .mobile-view {
  width: 100%;
  margin-bottom: 16px;
}
.card .mobile-view button {
  width: calc(50% - 1px);
  font-size: 14px;
  font-weight: 400;
  color: var(--black-color);
  padding: 4px 16px;
  background: none;
  border: 1px solid #fbbfa7;
  box-shadow: rgb(23 27 30 / 10%) 0px 2px 4px;
  text-transform: capitalize;
  border-radius: 0px;
  text-align: initial;
  display: inline-block;
  line-height: 20px;
  height: 100%;
}
.card .mobile-view button svg {
  margin-right: 8px;
}
.card .mobile-view button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.card .mobile-view button span.btn-sub-title {
  display: block;
  font-size: 12px;
  font-weight: 400;
  color: rgb(79, 94, 113);
  line-height: 16px;
}
.card .mobile-view button:nth-of-type(2) {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.border-modal .input-wapper {
  border-radius: 0px !important;
  border-top: 0px;
  box-shadow: none;
  border-bottom: 1px solid rgb(186, 199, 213);
}
.modal-container-data {
  max-height: 80.7vh;
  overflow-y: auto;
}
.border-modal.modal-bottom .MuiDrawer-paperAnchorBottom {
  border-radius: 0px;
}
.MuiPaper-elevation .input-wapper {
  height: 56px;
  border-radius: 0px;
}
.MuiPaper-elevation .input-wapper .search-label {
  font-size: 16px;
}
.border-modal {
  border-radius: 0px;
}
.banner-content {
  width: 100%;
}

.card.cars .input-wapper {
  height: 48px;
}

.card.cars .time-date .input-wapper select {
  padding: 16px 3px 0px 33px;
}

.card.cars .input-wapper .svg-input {
  position: absolute;
}
.card.cars .time-date .input-wapper span.search-label {
  top: -8px;
  position: absolute;
  left: 24px;
  font-size: 12px;
}
@media only screen and (min-width: 576px) {
  .banner-bg {
    padding-top: 58px;
  }
  .mobile-hide {
    display: none;
  }
  .card-above .tab {
    padding: 8px 20px;
  }
}

@media only screen and (min-width: 768px) {
  .banner-bg {
    padding-top: 86px;
  }

  .card.cars .block-group .block-group-item:nth-of-type(2),
  .card.cars .block-group .block-group-item:nth-of-type(1) {
    width: calc(50% - 4px);
    position: relative;
  }

  .card.cars .block-group .block-group-item {
    width: calc(25% - 6px);
    position: relative;
  }

  .card.flights .block-group .block-group-item {
    width: calc(25% - 6px);
    position: relative;
  }
  .hide-returnField.card.flights .block-group .block-group-item {
    width: calc(33.1% - 6px);
  }
  .card .block-group .block-group-item {
    width: calc(33% - 6px);
  }
}

@media only screen and (min-width: 1200px) {
  .job-details .banner-bg {
    padding-top: 253px;
    padding-bottom: 68px;
  }

  .banner-bg {
    padding-top: 86px;
    padding-bottom: 68px;
  }
  .search-btn.btn {
    margin-left: 8px;
  }
  .banner .container .card {
    width: 1120px;
  }
  .banner .container .card.card-above {
    min-width: auto;
  }
}
@media only screen and (min-width: 1300px) {
  .job-details .home-image img {
    object-position: 100% 0%;
    height: auto;
  }
}
