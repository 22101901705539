.job-list-heading p {
  display: inline-block;
  color: #a8a8a8;
  font-size: 16px;
  margin-bottom: 30px;

  width: 20%;
}
.featured-job p.job-title,
.job-list-heading p.job-title,
.skeleton .job-title {
  width: 60%;
}
.skeleton .job-department {
  margin: 0px 24px;
}
.featured-job p {
  width: 20%;
  display: inline-block;
  font-size: 16px;
}
.featured-job {
  border-bottom: 1px solid #cdd7eb;
  padding: 25px 0 25px;
  margin: 0 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.featured-job:hover {
  background: var(--primary-color);
  margin: 0 -20px;
  padding: 25px 20px 25px;
  box-shadow: 0 20px 30px rgb(42 42 46 / 20%);
  border-bottom: 1px solid var(--primary-color);
}
.featured-job:hover p {
  color: #fff;
}
.featured-job-section button.main-booking-btn {
  margin: 30px auto;
}
@media only screen and (min-width: 1200px) {
  .job-page .banner .container .card {
    padding: 24px 24px 16px 24px;
  }
}
@media screen and (max-width: 767px) {
  .featured-job p.job-title,
  .skeleton .job-title {
    width: 100%;
    margin-bottom: 5px;
    font-weight: 900;
  }
  .job-list-heading {
    display: none;
  }

  .featured-job p {
    width: 50%;
  }
  .featured-job {
    padding: 10px 0;
  }
  .featured-job p.job-location {
    text-align: right;
  }
  .featured-job:hover {
    margin: 0;
    padding: 10px 10px 10px;
  }
}
