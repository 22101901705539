.search-sideBar-wrapper {
  width: 268px;
  margin-top: 24px;
  background-color: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: rgb(37 42 49 / 16%) 0px 0px 2px 0px,
    rgb(37 42 49 / 12%) 0px 1px 4px 0px;
  overflow: hidden;
  flex-shrink: 0;
  height: max-content;
  position: sticky;
  top:140px;
  display: none;
}

.search-sideBar-wrapper .sidebar-img-wrapper {
  height: 160px;
}
.search-sideBar-wrapper .sidebar-content {
  padding: 24px;
  text-align: center;
}

.search-sideBar-wrapper .sidebar-content .main-title {
  margin-bottom: 0px;
}
.search-sideBar-wrapper .sidebar-content p {
  margin: 12px 0px 16px 0px;
}

@media (min-width: 1200px) {
  .search-sideBar-wrapper {
    display: block;
  }
}


#map,
#pano {
  float: left;
  height: 100%;
  width: 50%;
}