.account-page .input-group-container {
  max-width: 400px;
  margin-bottom: 16px;
}
.account-page .main-booking-btn.bigBtn {
  margin-left: auto;
  grid-column-start: 2;
  /* margin: 0px 16px; */
  margin-right: 10px;
}
.account-page .job-list-heading p {
  margin-bottom: 8px;
}
@media (min-width: 768px) {
  .account-page .form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 824px;
  }
}
