.booking-info {
  background: rgb(245, 247, 249);
  padding: 24px;
}
.booking-info .card-card {
  max-width: 930px;
}
.booking-info-wrapper {
  padding: 16px;
  width: auto;
  display: inline-flex;
  align-items: center;
  background: #4caf500f;
  width: 100%;
  border: 1px solid #4caf50;
}
.booking-info-wrapper p {
  font-weight: 400;
}
.booking-info-wrapper p:first-child.mb-12 {
  color: #4caf50;
  font-size: 18px;
}
.booking-info span.circle svg.air-plane {
  width: 60px;
  height: 60px;
  fill: #ececec;
  transform: rotate(45deg);
}
svg.check-icon {
  position: absolute;
  fill: #4caf50;
  width: 58px;
  height: 58px;
  right: 13px;
  top: 15px;
  z-index: 9;
}
.booking-info span.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border: 5px solid #4caf50;
  border-radius: 50%;
  position: relative;
  margin-bottom: 24px;
  margin-right: 24px;
}
.gray.main-title {
  color: rgb(79, 94, 113);
}
.gray.main-title strong {
  color: var(--black-color);
}

@media (max-width: 767px) {
  .booking-info-wrapper {
    display: block;
  }
}
