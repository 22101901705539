.airports .tab p {
  font-size: 22px;
  font-weight: 700;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 11px;
}
.airports {
  background-color: #f8f8f8;
  padding-bottom: 32px;
}
.airports .tab.active p,
.airports .tab:hover p {
  color: var(--primary-color);
}
.airports .tab {
  position: relative;
  cursor: pointer;
  transition: 0.3s ease;
}
.airports .tab:hover:after,
.airports .tab.active:after {
  content: "";
  height: 3px;
  border-radius: 2px;
  width: 100%;
  position: absolute;
  left: 0;
  background-color: var(--primary-color);
  bottom: -1px;
  cursor: pointer;
}
.job-page.airports .banner .container .card {
  padding-bottom: 0px;
}
.card-flight {
  padding: 25px 0 25px;
  margin: 0 0px;

  justify-content: space-between;
  display: none;
}
.card-flight:not(:last-child),
.job-page.airports .result-card-content {
  border-bottom: 1px solid #cdd7eb;
}
.card-flight-name {
  display: flex;
  align-items: center;
}
.card-flight-name img {
  max-width: 40px;
  margin-right: 12px;
}
.airline-logo {
  max-width: 30px;
  margin: 0px 4px;
}
.job-page.airports .result-card-content {
  padding: 16px 0px;
}
.result-card-place div {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.title-header {
  margin-bottom: 16px;
}
.featured-job-section {
  position: relative;
  border: 1px solid rgb(239, 242, 245);
  background: rgb(255, 255, 255);
  padding: 16px;
  border-radius: 3px;
  margin-bottom: 32px;
}
.airports-tabs {
  display: inline-flex;
  border-left: 1px solid rgb(239, 242, 245);
  border-radius: 10px 10px 0px 0px;
  margin-bottom: -2px;
  z-index: 9;
  position: relative;
}
.block-group-item svg {
  margin: 2px 8px;
}
.airports-tabs p {
  padding: 16px 18px;
  cursor: pointer;
  background: rgb(232, 237, 241);
  border-radius: 0px 20px 0px 0px;

  border-top: 1px solid rgb(239, 242, 245);
  min-width: 120px;
  box-sizing: content-box;
  white-space: nowrap;
}
.airports-tabs p.active {
  border-radius: 0px 20px 0px 0px;
  box-shadow: none;
  background: #fff;
  z-index: 10;
  color: var(--primary-color);
}
.airports-tabs p.active svg {
  fill: var(--primary-color);
}
.airports-tabs p:not(:first-child) {
  margin-left: -16px;
  text-align: center;
  border-right: 1px solid rgb(239, 242, 245);
}
.empty-data-msg {
  padding: 24px 16px;
  background-color: rgb(239, 242, 245);
}
.empty-data-msg h1 {
  text-align: center;
  font-style: italic;
  margin: 0px;
}
.job-title .badge {
  font-size: 14px;
}
.skeleton {
  display: flex;
  justify-content: space-between;
}
.skeleton div {
  width: 20%;
}
.card-flight-name .badge {
  padding: 5px 16px;
}
.badge.active {
  background-color: #a7fe43;
}
.badge.scheduled {
  background-color: #82d423;
}
.badge.delayed {
  background-color: #ff4c4c;
}
.badge.landed {
  background-color: #a3e7f0;
}
.card-flight p {
  white-space: pre-wrap;
}
svg.plane-up {
  transform: rotate(45deg);
}
svg.landed {
  transform: rotate(120deg);
}
svg.icon-180 {
  transform: rotate(180deg);
}
.info-msg {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row;
}
.info-msg svg {
  fill: #82d423;
  margin: 0px 6px;
  width: 12px;
  height: 12px;
}
.info-msg p {
  display: flex;
}
.info-msg p strong {
  margin-right: 4px;
}
@media only screen and (max-width: 1200px) {
  .airports .block-group .block-group-item {
    width: calc(25% - 6px);
  }
}

@media only screen and (max-width: 768px) {
  .airports .tab p {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    padding-bottom: 6px;
  }
  .airports .tab p svg {
    margin: 5px 8px;
  }
  .job-page.airports .banner .container .card {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 768px) {
  .flight-details-mobile {
    display: none;
  }
  .card-flight {
    display: flex;
  }
}
@media only screen and (max-width: 567px) {
  .info-msg {
    position: absolute;
    top: -28px;
  }
  .airports .tab p,
  .airports-tabs p {
    font-size: 14px;
  }

  .airports-tabs p svg {
    display: none;
  }
  .job-page.airports .banner .container .card {
    padding: 16px 16px 0px 16px;
  }
  .airports .tab p svg {
    width: 16px;
    height: 16px;
  }
}
