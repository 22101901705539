.select-labels-container {
  position: relative;
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 24px;
}
.select-ele {
  appearance: none;
  background: rgb(255, 255, 255);
  cursor: pointer;
  color: #000;
  font-weight: 500;
  font-family:
    "Circular Pro",
    -apple-system,
    ".SFNSText-Regular",
    "San Francisco",
    "Segoe UI",
    "Helvetica Neue",
    "Lucida Grande",
    sans-serif;
  font-size: 14px;
  height: 44px;
  padding: 0px 40px 0px 12px;
  outline: none;
  width: 100%;
  transition: box-shadow 0.15s ease-in-out 0s;
  z-index: 2;
  border-radius: 3px;
  border: 0px;
  box-shadow: rgb(186 199 213) 0px 0px 0px 1px inset;
}
.select-ele.error {
  box-shadow: rgb(210 28 28) 0px 0px 0px 1px;
}
.select-ele option {
  color: rgb(37, 42, 49);
}

.select-ele-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 8px;
  color: rgb(37, 42, 49);
  pointer-events: none;
  z-index: 3;
  height: 44px;
}
