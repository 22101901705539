/* Date Range Calendar */
@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";

@font-face {
  font-family: "Circular Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src:
    local("Circular Pro Book"),
    local("CircularPro-Book"),
    url(https://static-data.kiwi.com/fonts/circular-pro/book.woff2) format("woff2");
}
@font-face {
  font-family: "Circular Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src:
    local("Circular Pro Book Italic"),
    local("CircularPro-BookItalic"),
    url(https://static-data.kiwi.com/fonts/circular-pro/book-italic.woff2) format("woff2");
}
@font-face {
  font-family: "Circular Pro";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src:
    local("Circular Pro Medium"),
    local("CircularPro-Medium"),
    url(https://static-data.kiwi.com/fonts/circular-pro/medium.woff2) format("woff2");
}
@font-face {
  font-family: "Circular Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src:
    local("Circular Pro Bold"),
    local("CircularPro-Bold"),
    url(https://static-data.kiwi.com/fonts/circular-pro/bold.woff2) format("woff2");
}

:root {
  --primary-color: #f15e22;
  --primary-color-hover: #f05112;

  --secondary-color: #124786;
  --black-color: rgb(37, 42, 49);
  --primary-font: CircularStd-Medium;
  --primary-light-font: "CircularStd-Light";
}

.bg-primary-light {
  background-color: rgb(232, 237, 241) !important;
}
html {
  scroll-behavior: smooth;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
a,
span,
li,
button,
label,
span,
div {
  font-family:
    "Circular Pro",
    -apple-system,
    ".SFNSText-Regular",
    "San Francisco",
    "Segoe UI",
    "Helvetica Neue",
    "Lucida Grande",
    sans-serif !important;

  color: rgb(46, 53, 59);
  font-weight: normal;
  font-size: 14px;
}
body {
  background-color: #fff;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family:
    "Circular Pro",
    -apple-system,
    ".SFNSText-Regular",
    "San Francisco",
    "Segoe UI",
    "Helvetica Neue",
    "Lucida Grande",
    sans-serif;
}
a {
  text-decoration: none;
}
.btn:not([disabled]) a {
  color: #fff;
}
.gray-color {
  color: rgb(79, 94, 113);
}
.light-gray {
  color: #7c8b99;
}
.primary-color {
  color: var(--primary-color);
}
.secondary-color {
  color: var(--secondary-color);
}
strong {
  font-weight: 500;
  line-height: 1.4;
}
.bolder {
  font-weight: 700 !important;
}
.gray {
  color: rgb(79, 94, 113);
}
.light-gray {
  color: #94a2b0;
}

ul {
  list-style-type: none;
}
a {
  cursor: pointer;
}
a:hover {
  color: var(--primary-color);
}
p {
  margin: 0px;
}
.bold {
  font-weight: 500;
}
.title {
  font-size: 28px;
}
.main-title {
  font-size: 22px;
  color: var(--black-color);
  margin-bottom: 24px;
}
label {
  font-weight: 500;
  margin-bottom: 4px;
}
.medium-font-size {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.small-font-size {
  font-size: 12px;
}
.sub-main-title {
  font-size: 20px;
}
.bg {
  background-color: rgb(245, 247, 249);
}
.app-link {
  color: var(--primary-color);
  font-size: 14px;
}
.app-link:hover {
  text-decoration: underline;
}

.main-view {
  position: absolute;
  inset: 64px 0px 0px;
  overflow: visible;
}
.primary-text .MuiListItemText-primary {
  font-size: 14px;
  font-weight: 500;
}
.primary-text .MuiListItemText-secondary {
  font-size: 12px;
  color: rgb(79, 94, 113);
}
.orange-color {
  color: var(--primary-color);
}
.mb-24 {
  margin-bottom: 24px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-8 {
  margin-bottom: 8px;
}
.flex-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
hr {
  width: 100%;
  height: 1px;
  background: rgb(239, 242, 245);
  box-sizing: border-box;
  border-style: none;
  margin-top: 0px;
  margin-bottom: 7px;
  opacity: 1;
}
svg {
  width: 24px;
  height: 24px;
  display: inline-block;
  fill: rgb(46, 53, 59);
  position: relative;
}
input:disabled {
  background: #f2f2f2;
}

.stripe-form-container {
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stripe-form-container form {
  background-color: #fff;
  position: relative;
  z-index: 9;
  max-width: 700px;
  padding: 24px;
  margin: auto;
  width: 900px;
  box-shadow: 0 10px 10px -5px;
  border-radius: 10px;
}
.phone-input .form-control {
  border: none;
}
.feedback-stick {
  background-color: #fff;
  position: fixed;
  right: 0px;
  /* top: 250px; */
  display: flex;
  z-index: 99;
  align-items: center;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  border: 0.5px rgb(229, 229, 229);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  box-shadow:
    rgba(37, 42, 49, 0.16) 0px 0px 2px 0px,
    rgba(37, 42, 49, 0.12) 0px 1px 4px 0px;
  transform: rotate(-90deg) translate(25%);
  transform-origin: right bottom;
  padding: 4px 10px;
}

.feedback-stick p {
  padding: 0px 0px 0px 4px;
  font-size: 14px;
}
.home-image .video-react-has-started .video-react-control-bar,
.home-image .video-react .video-react-big-play-button {
  display: none;
}

/* Sale tag */

/* common */
.ribbon {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: "";
  display: block;
  border: 3px solid #124786;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 138px;
  padding: 5px 0;
  background-color: #124786;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

/* top left*/
.ribbon-top-left {
  top: -3px;
  left: -3px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  left: -3px;
  top: 32px;
  transform: rotate(45deg);
}

/* top right*/
.ribbon-top-right {
  top: -3px;
  right: -3px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -9px;
  top: 29px;
  transform: rotate(45deg);
  color: #fff;
}

.wdp-ribbon {
  display: inline-block;
  padding: 2px 15px;
  position: absolute;
  right: 0px;
  top: 20px;
  line-height: 24px;
  height: 24px;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  border-radius: 0;
  text-shadow: none;
  font-weight: normal;
  background-color: #1eb5ff !important;
}

.wdp-ribbon-two:before,
.wdp-ribbon-two:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -14px;
  top: 0;
  border: 9px solid transparent;
  border-width: 14px 8px;
  border-right-color: #1eb5ff;
}
.wdp-ribbon-two:before {
  border-color: #1eb5ff;
  border-left-color: transparent !important;
  left: -9px;
}
.wdp-ribbon-four {
  right: 10px;
  top: 10px;
  line-height: 60px;
  height: 60px;
}
.wdp-ribbon-five {
  background: #1eb5ff;
  right: 10px;
  top: 10px;
  padding: 0px;
  height: 60px;
  width: 60px;
  text-align: center;
  vertical-align: middle;
  line-height: 60px;
  border-radius: 50%;
}
.wdp-ribbon-six {
  background: none !important;
  position: relative;
  box-sizing: border-box;
  position: absolute;
  width: 65px;
  height: 65px;
  top: 0px;
  right: 0px;
  padding: 0px;
  overflow: hidden;
}
.wdp-ribbon-inner-wrap {
  -ms-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  -webkit-transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
.wdp-ribbon-border {
  width: 0;
  height: 0;
  border-right: 65px solid #1eb5ff;
  border-bottom: 65px solid transparent;
  z-index: 12;
  position: relative;
  top: -20px;
}
.wdp-ribbon-text {
  font-size: 13px;
  font-weight: bold;
  line-height: 13px;
  position: absolute;
  z-index: 14;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 15px;
  left: -5px;
  width: 91px;
  text-align: center;
}
.rdrDefinedRangesWrapper {
  display: none;
}

.progress-bar {
  width: 100px;
  height: 16px;
  background-color: #e0e0e0; /* Light gray background */
  border-radius: 15px;
  flex-direction: row;
  overflow: hidden;
  display: flex; /* Align child segments horizontally */
  margin: 20px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  justify-content: flex-start;
}

.progress-bar-segment {
  height: 100%;
  transition: width 0.3s ease-in-out; /* Smooth transition */
}
