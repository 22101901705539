.tabs-header {
  background-color: var(--primary-color);
  padding: 16px;
  margin: 8px 0px;
}
.tabs-header h1,
.tabs-header p {
  color: #fff;
  margin: 0px;
}
.tabs-header h1 svg {
  fill: #fff;
}
