.navbar {
  box-shadow:
    rgb(37 42 49 / 16%) 0px 0px 2px 0px,
    rgb(37 42 49 / 12%) 0px 2px 4px 0px;
  padding: 12px;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  transition: transform 0.3s ease-in-out 0s;
  transform: translate3d(0px, 0px, 0px);
  background-color: #fff;
  padding: 0px;
  height: 76px;
}

.dropdown-menu.currancy {
  width: 93px;
  min-width: 93px;
}
.eur-flag {
  width: 24px;
  height: 20px;
}
.navbar-light .navbar-nav .nav-item .nav-link {
  color: var(--black-color);
  font-weight: 500;
}
.navbar-light .navbar-nav .nav-item .nav-link.active,
.navbar-light .navbar-nav .nav-item .nav-link:hover {
  color: var(--primary-color);
}
.img-logo {
  max-width: 120px;
}

.box-country:hover,
.box-country.active {
  border-color: var(--primary-color);
  cursor: pointer;
}
.right-dropdown.dropdown-menu {
  left: auto;
  right: 0px;
}
.dropdown-toggle.avatar::after {
  visibility: hidden;
}
.user-type {
  padding: 4px;
  border-radius: 3px;
}
.logo-svg {
  width: auto;
  height: 68px;
}
.partners-logo-header img {
  width: 30px;
  margin: 0px 5px;

  height: 27px;
}
.partners-logo-header {
  margin-top: 6px;
}

.nav-item.dropdown {
  list-style: none;
}

.navbar .main-booking-btn svg {
  width: 16px;
}

@media (max-width: 992px) {
  .navbar .container-fluid {
    background: #fff;
  }
}
@media (max-width: 768px) {
  .logo-svg {
    width: 132px;
    height: auto;
  }
}
