.input-otp-container {
  justify-content: center;
  margin: 24px;
}
.input-otp-container .input-otp {
  width: 50px !important;
  height: 60px !important;
  border-radius: 6px !important;
  margin: 0px 12px;
  font-size: 18px;
  border: 1px solid var(--black-color);
}
.input-otp-container .input-otp:focus {
  border: 1px solid var(--primary-color);
  outline: 0;
}
