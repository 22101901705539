.main-booking-btn {
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  appearance: none;
  max-width: 100%;
  background: rgb(232, 237, 241);
  border: 0px;
  padding: 0px 12px;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
  cursor: pointer;
  outline: 0px;
  width: auto;
  color: rgb(37, 42, 49);
  transition: all 0.15s ease-in-out 0s;
  white-space: nowrap;
}
.main-booking-btn:hover {
  background: #dfe5eb;
}
.main-booking-btn.bigBtn {
  line-height: 1.4;
  padding: 0px 16px;
  margin: auto;
  border-radius: 6px;
}
.main-booking-btn.primary-color {
  background-color: var(--primary-color);
  color: #fff;
}
.main-booking-btn.primary-color:hover {
  background-color: var(--primary-color-hover);
}
.main-booking-btn.icon-box {
  width: 32px;
  padding: 0px;
}

button:disabled {
  background-color: rgba(239, 239, 239, 0.3) !important;
  color: rgba(16, 16, 16, 0.3) !important;
  cursor: inherit;
}
button:disabled:hover {
  background-color: rgba(239, 239, 239, 0.3) !important;
}
button:disabled svg {
  color: rgba(16, 16, 16, 0.3) !important;
}
