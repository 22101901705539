.footer {
  padding: 84px 0px 0px 0px;
  border-top: 1px solid #f2f2f2;
}

.footer ul li {
  margin-bottom: 12px;
  color: var(--black-color);
  text-decoration: underline;
}
.footer ul li:hover {
  color: var(--primary-color);
  text-decoration: none;
}

.social-media {
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  justify-content: center;
}
.social-media svg {
  width: 32px;
  margin: 16px;
  color: var(--primary-color);
  fill: var(--primary-color);
}
