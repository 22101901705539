.calendar-container {
  min-width: 420px;
  width: fit-content;
}
.calendar-container {
  text-align: center;
  padding: 16px;
}

.header-container-data {
  display: flex;
  align-items: flex-end;
  margin: 0px 5px;
}
.header-container-data label {
  text-align: left;
  display: block;
}
.header-container-data .nav-item.dropdown {
  box-shadow: rgb(186 199 213) 0px 0px 0px 1px inset;
  margin-right: 5px;
  height: 44px;
}
.block-group-item .time-days svg {
  margin: 0px;
}
.block-group-item .time-days {
  white-space: nowrap;
  margin: 8px;
}
.header-container-data input {
  margin: 0px;
  height: 44px;
  border-radius: 3px;
  box-shadow: rgb(186 199 213) 0px 0px 0px 1px inset;
  border: none;
  padding: 0px 6px;
  color: rgb(37, 42, 49);
  font-weight: 400;
  font-size: 14px;
}
.header-container-data input:focus,
.selected-date {
  border-color: var(--primary-color);
  outline: none;
  border: 1px solid var(--primary-color) !important;
  box-shadow: none !important;
}
.selected-date {
  color: var(--primary-color) !important;
}
.react-datepicker__day:hover {
  background-color: #fbbfa7 !important;
}
.header-container-data .input-group {
  margin: 0px 5px;
  align-items: center;
}
.header-container-data .input-group input {
  width: 100%;
}
.header-container-data .input-group label {
  margin-right: 8px;
}
.calender-customize .react-datepicker {
  border: none;
  width: 100%;
}

.calender-customize .react-datepicker__header {
  background-color: inherit;
  border-bottom: none;
}

.calender-customize .react-datepicker-popper {
  box-shadow:
    rgba(37, 42, 49, 0.16) 0px 0px 2px 0px,
    rgba(37, 42, 49, 0.12) 0px 1px 4px 0px;
  border: 1px rgb(229, 229, 229);
  border-radius: 10px;
  z-index: 99;
}
.calendar-container .react-datepicker__month-container {
  width: 50%;
}
.calender-customize .react-datepicker__day-names,
.calender-customize .react-datepicker__week {
  justify-content: space-between;
  display: flex;
}
.calender-customize .react-datepicker__day-names {
  margin: 0px 3px;
}

.calender-customize .react-datepicker__current-month,
.calender-customize .react-datepicker__navigation {
  display: inline-block;
  padding: 5px 12px;
  background-color: #fbbfa7;
  border-radius: 3px;
  margin: 16px 20px;
  color: var(--black-color);
}
.react-datepicker__day--keyboard-selected {
  background-color: #fbbfa7;
}
.calender-customize .react-datepicker__navigation {
  margin: 20px 10px;
}
.calender-customize .react-datepicker__navigation-icon::before {
  border-color: var(--black-color);
  width: 6px;
  height: 6px;
  border-width: 2px 2px 0 0;
}
.calender-customize .react-datepicker__navigation-icon--previous {
  right: 18px;
  top: 7px;
}
.calender-customize .react-datepicker__navigation-icon--next {
  left: 18px;
  top: 7px;
}
.calender-customize .react-datepicker__navigation-icon {
  position: inherit;
}

.calender-customize .react-datepicker__day-name {
  font-size: 12px;
  font-weight: 400;
  color: rgb(79, 94, 113);
  line-height: 16px;
  text-align: center;
  margin: 6px;
}
.calender-customize .react-datepicker__current-month {
  font-weight: 400 !important;
  font-size: 12px;
}

.calender-customize .react-datepicker__day:not(.react-datepicker__day--disabled) {
  font-weight: 400 !important;
  font-size: 16px;
  color: rgb(37, 42, 49);
  width: 2.1rem;
  line-height: 2rem;
}
.calender-customize
  .react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--in-range
  ),
.calender-customize .react-datepicker__day--in-range,
.calender-customize .react-datepicker__day--selected {
  background-color: var(--primary-color);
}
.calendar-date {
  position: relative;
}
.tabs {
  position: absolute;
  z-index: 99;
  top: 0px;
  left: 0px;
  display: flex;
  height: 44px;
  width: 100%;
  overflow: hidden;
  background-color: rgb(239, 242, 245);
}
.tabs button.tab-btn {
  background: #fff;
  font-size: 14px;
  font-weight: 700;
  height: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: 3px 3px 0px 0px;
  background: transparent;
  padding: 0px 16px;
}
.tabs button.tab-btn.active {
  background-color: #fff;
  box-shadow:
    rgb(37 42 49 / 16%) 0px 4px 8px 0px,
    rgb(37 42 49 / 24%) 0px 8px 24px 0px;
  border-radius: 3px 3px 0px 0px;
}
.date-picker-resize .input-wapper {
  margin: 46px 0px 6px 0px;
  box-shadow: var(--primary-color) 0px 0px 0px 2px;
}

.date-picker-resize .input-wapper .search-label svg {
  margin-right: 0px;
  color: var(--primary-color);
}
.container-search .btns-wrapper button {
  width: auto;
}

.date-picker-container {
  position: absolute;
  left: 0px;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  max-height: calc(100% - 80px);
  background-color: #fff;
}
.res-calendar .btns-wrapper {
  position: fixed;
  width: 100%;
  left: 0px;
  bottom: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: end;
  box-sizing: border-box;
  height: 64px;
  padding: 12px;
  background-color: rgb(255, 255, 255);
  border-radius: 0px 0px 3px 3px;
  box-shadow: rgb(0 0 0 / 10%) 0px -2px 6px;
}
.res-calendar.container-search {
  position: inherit;
}
.full-size-modal .MuiDrawer-paper {
  height: 100%;
}

@media only screen and (max-width: 1200px) {
  .banner-content {
    flex-wrap: wrap;
  }
  .search-btn {
    width: 100%;
    margin: 0px;
  }
  .block-group .block-group-item {
    width: calc(50% - 8px);
  }
}

@media only screen and (max-width: 600px) {
  .calender-customize,
  .block-group .block-group-item,
  .calender-customize .react-datepicker__month-container,
  .container-search {
    width: 100%;
  }
  .block-group-item .switch-icon {
    top: 33px;
    left: 20px;
  }
  .banner .container h1 {
    font-size: 28px;
    line-height: 30px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .suggest-card {
    margin-bottom: 24px;
  }
  .header-container-data .nav-item.dropdown {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
  .header-container-data {
    flex-wrap: wrap;
  }
  .header-container-data .input-group {
    margin-bottom: 10px;
  }
  .calendar-date {
    position: inherit;
  }
  .banner-content {
    flex-wrap: wrap;
  }
  .banner .container .card {
    margin-bottom: -40px;
    width: 100%;
  }
}

.date-range-mobile.rdrDateRangePickerWrapper {
  width: 100%;
  justify-content: center;
  background: #fff;
}

.date-range-mobile .rdrDateDisplay {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.date-range-mobile .rdrDateDisplay span {
  width: 100%;
  gap: 12px;
}

.date-range-mobile .rdrDateDisplayItem + .rdrDateDisplayItem {
  margin-left: 0px;
}
