.login-modal .input-group-container {
  margin-bottom: 16px;
}
.login-modal button.primary-color {
  margin-top: 24px;
  margin-bottom: 16px;
}
u {
  cursor: pointer;
}
.login-modal input:not(input[name="password"]){
  text-transform: lowercase;
}