.dialog-container .MuiPaper-root {
  background-color: #fff;
  width: 100%;
  margin: 0px;
  border-radius: 9px;
}
.dialog-header {
  padding: 24px 16px 0px;
}
.dialog-header h2 {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
}
.layover-text-modal {
  color: rgb(79, 94, 113);
  font-size: 12px;
  padding: 8px;
  margin-left: 96px;
  position: relative;
}
.result-details-flight.layover-modal:after {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'%3E%3Ccircle cx='60' cy='60' r='30' fill='%23bac7d5'/%3E%3C/svg%3E")
    0% 0% / 4px 4px;
  width: 4px;
  left: 86px;
}
.result-details-flight.layover-modal svg {
  position: absolute;
  left: -15px;
  background-color: rgb(245, 247, 249);
}
.result-details-flight.layover-modal:before {
  left: 86px;
}
.dialog-header .close-container {
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 800;
  -webkit-box-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  height: 52px;
  width: 100%;
  max-width: 539.997px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  transition: box-shadow 0.15s ease-in-out 0s,
    background-color 0.15s ease-in-out 0s;
}
.dialog-header .close-container span {
  margin-right: 4px;
}
.dialog-container-fluid {
  margin-top: 40px;
  /* margin-bottom: 92px; */
}
.dialog-container-fluid > :nth-child(2) {
  padding-top: 0px;
  border-top: 0px;
}
.dialog-conetnt {
  padding-bottom: 24px;
  margin-bottom: 0px;
  border-top: 1px solid rgb(239, 242, 245);
  background-color: rgb(245, 247, 249);
  width: 100%;
  padding: 24px 16px;
}

.trip-wrapper h2 {
  margin: 0px -40px 16px;
  padding: 0px 40px 16px;
  border-bottom: 1px solid rgb(239, 242, 245);
}

.trip-wrapper header,
.result-details-section-grid {
  display: flex;
  flex-wrap: wrap;
}
.sector-segment {
  justify-content: center;
  width: 5.75em;
  display: flex;
  align-items: center;
}
.trip-wrapper .sector-section {
  padding: 0px 12px 24px 0px;
  display: flex;
  position: relative;
}
.result-details-section-grid > :nth-child(2n) {
  width: calc(100% - 5.75em);
}
.sector-segment p {
  padding: 4px 0px 4px 8px;
  white-space: nowrap;
  text-align: center;
}

.result-details-flight {
  width: calc(100% - 5.75em);
  padding: 4px 0px;
  position: relative;
}
.result-details-slack {
  display: flex;
  flex-flow: row nowrap;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  -webkit-box-pack: start;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
}
.ariline-logo {
  width: 40px;
  position: absolute;
  top: 30px;
}
.results-card-logo {
  height: 25px;
  margin: 0px 2px;
  padding: 1px 0px;
}
.trip-wrapper svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  fill: rgb(79, 94, 113);
  position: relative;
  z-index: 2;
  min-width: 16px;
}

.trip-wrapper header .sector-section:last-of-type::before,
.result-details-flight:before {
  position: absolute;
  z-index: 1;
  left: 6px;
  width: 4px;
  content: "";
  top: 50%;
  height: 50%;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 120 120'%3E%3Ccircle cx='60' cy='60' r='30' fill='%23bac7d5'/%3E%3C/svg%3E")
    0% 0% / 4px 4px;
}
.result-details-flight:before {
  top: 0px;
}

.result-details-flight:after,
.class-name:after {
  bottom: 0px;
  height: 50%;
  position: absolute;
  z-index: 1;
  left: 7px;
  width: 2px;
  background: rgb(232, 237, 241);
  content: "";
}
.result-details-section {
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow: rgb(37 42 49 / 16%) 0px 0px 2px 0px,
    rgb(37 42 49 / 12%) 0px 1px 4px 0px;
}
.class-type {
  padding: 4px 24px;
  background: rgb(245, 247, 249);
  position: relative;
}
.class-type.class-name .badge {
  margin-top: 8px;
}
.class-name:after {
  height: 100%;
  background-color: rgb(208, 233, 251);
}
.flight-airline {
  background: rgb(245, 247, 249);
}
.flight-airline-badge {
  padding: 12px 12px 12px 0px;
}
.flight-airline-badge svg {
  transform: rotate(180deg);
}
.result-details-flight.flight-airline-badge:before {
  background: rgb(232, 237, 241);
  left: 7px;
  width: 2px;
}
footer .arrival-point {
  padding: 24px 0px 12px;
}
footer .result-details-flight:after {
  display: none;
}
.residents-details {
  display: flex;
  padding-bottom: 12px;
}
.residents-details p {
  font-weight: 700;
}
.dialog-wrapper {
  position: relative;
  overflow-x: hidden;
}
.dialog-footer {
  width: 100%;
  box-shadow: none;
  padding: 32px 30px;
  background-color: #fff;
}
.dialog-footer-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: end;
}
.dialog-footer-wrapper p {
  margin-right: 16px;
  font-weight: 700;
}
@media (min-width: 576px) {
  .dialog-container .MuiPaper-root {
    position: relative;
    top: 0px;
  }
  .dialog-header {
    padding: 40px 40px 0px;
  }
  .dialog-conetnt {
    padding: 40px;
    padding-bottom: 40px;
    /* margin-bottom: 92px; */
  }
}
