.features {
  margin-top: 4rem;
  margin-bottom: 4rem;
  text-align: center;
}
.features h1 {
  font-size: 26px;
  color: var(--black-color);
  margin-bottom: 2rem;
}
.banner-desktop {
  position: relative;
  max-width: 1320px;
  min-height: 320px;
  margin: 0px auto;
  padding: 0px 24px;
  overflow: hidden;
  transition: box-shadow 0.3s ease-out 0s;
}
.banner-desktop .image-wrapper {
  display: flex;
  align-items: flex-start;
  -webkit-box-pack: center;
  justify-content: center;
  max-height: 363px;
  margin-right: -120px;
  position: relative;
}
.partners-logo {
  display: flex;
  margin: 40px 0px;
  justify-content: center;
}
.partners-logo img {
  display: inline-block;
  padding: 0px 2.2rem;
  opacity: 0.25;
  width: auto;
  height: auto;
  transition: opacity 0.2s ease;
  cursor: default;
  max-width: 170px;
}

.partners-logo img:hover {
  opacity: 1;
}
.banner-desktop .image-wrapper img {
  width: 100%;
}
.banner-desktop h1.main-title {
  font-size: 28px;
  color: rgb(37, 42, 49);
}
.banner-desktop p {
  color: rgb(37, 42, 49);
  margin-bottom: 24px;
}
.banner-desktop svg {
  display: inline-block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  vertical-align: middle;
  fill: currentcolor;
  color: rgb(0, 169, 145);
  margin-right: 12px;
}
.app-feature p {
  font-size: 16px;
  color: rgb(79, 94, 113);
  margin-bottom: 0px;
}
.banner-desktop .app-feature {
  margin-right: 24px;
  margin-bottom: 16px;
}

.banner-desktop a {
  margin-right: 12px;
  display: inline-block;
  margin-top: 12px;
}
.qr-con {
  position: absolute;
  right: 43%;
  top: 19%;
  width: 24%;
}
.qr-con p {
  font-size: 12px;
  text-align: center;
  margin-top: 12px;
}

@media only screen and (max-width: 600px) {
  .banner-desktop .row {
    flex-direction: column-reverse;
  }
  .banner-desktop .image-wrapper {
    margin: 0px;
    margin-bottom: 24px;
  }
  .banner-desktop .image-wrapper img {
    max-width: 220px;
  }
}
.dropdown-btn {
  display: none;
}
@media only screen and (min-width: 600px) {
  .mobile-view {
    display: none;
  }
  .dropdown-btn {
    display: flex;
  }
}

.video-container {
  position: relative;
  top: -220px; /* Default top position for desktop screens */
}

@media only screen and (max-width: 1000px) {
  .video-container {
    top: 0; /* Reset top position for smaller screens */
  }
}
