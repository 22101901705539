.MuiOutlinedInput-notchedOutline {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
input.MuiInputBase-input {
  margin-left: 1px;
}
.MuiFormControl-root {
  width: 100% !important;
}
.date-picker-container-css{
  display: flex;
    align-items: center;
    justify-content: center;
}
.date-picker-container-css .select-labels-container{
 margin:0px;
  min-width: 155px;
}
.date-picker-container-css .select-labels-container select{
box-shadow: none;
border-left: 1px solid rgb(186 199 213);
border-right: 1px solid rgb(186 199 213);
}