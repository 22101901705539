.search-form-wrapper .search-form {
  padding: 16px;
  position: relative;
}

.search-form-wrapper .search-form > div {
  max-width: 1400px;
  margin: 0px auto;
}
.search-form-wrapper .search-form .card {
  border: none;
}

.search-results-view {
  position: relative;
  /* display: flex; */
  flex-shrink: 0;
  align-items: flex-start;
  /* overflow: hidden; */
  background-color: rgb(245, 247, 249);
}

.active-filter {
  position: absolute;
  right: 0px;
}

.search-results-container {
  display: flex;
  width: 100%;
  max-width: 1320px;
  margin: 0px auto;
}

.search-form-filter {
  flex-shrink: 0;
  width: 268px;
  padding-top: 24px;
  display: none;
  height: fit-content;
  overflow: hidden;
  position: sticky;
  top: 110px;
}
.search-form-filter > div {
  max-height: calc(100vh - 120px);
  overflow-y: scroll;
  /* position: relative; */
  /* padding-right: 18px; */
  width: calc(100% + 18px);
}
.search-results-list {
  padding: 16px;
  max-width: 770px;
  margin-left: auto;
  width: 100%;
}
.search-form-filter .MuiList-root,
.search-form-filter .MuiList-root:hover,
.search-form-filter .MuiListItemButton-root:hover {
  background: none;
  padding: 0px;
}
.search-form-filter .MuiListItemButton-root {
  height: 28px;
  padding: 0px;
}
.search-form-filter svg,
.search-form-filter .MuiRadio-root {
  width: 16px;
  height: 16px;
}
.search-form-filter .item-flex svg {
  fill: rgb(79, 94, 113);
}
.search-form-filter .item-flex .btn-group svg {
  fill: var(--black-color);
  margin: 0px;
}
.search-form-filter .MuiCollapse-root {
  margin: 12px 0px;
  margin-bottom: 16px;
  padding-bottom: 12px;
  /* border-bottom: 1px solid rgb(232, 237, 241); */
}
.m-0 .mb-8 {
  margin-bottom: 0px;
}

.search-results-list {
  width: 100%;
}
.sortBy-quality {
  display: none;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
  white-space: nowrap;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow:
    rgb(37 42 49 / 16%) 0px 0px 2px 0px,
    rgb(37 42 49 / 12%) 0px 1px 4px 0px;
  margin-bottom: 16px;
}
.sortBy-quality .sortBy-quality-panel-item {
  width: 100%;
  max-width: 25%;
  padding: 12px 16px calc(9px);
  overflow: hidden;
  border-bottom: 3px solid rgb(255, 255, 255);
  cursor: pointer;
  transition: border-bottom 0.4s ease 0s;
}
.sortBy-quality .sortBy-quality-panel-item:hover,
.sortBy-quality .sortBy-quality-panel-item.active {
  border-bottom: 3px solid var(--primary-color);
}
.sortBy-quality .sortBy-quality-panel-item:hover p,
.sortBy-quality .sortBy-quality-panel-item.active p {
  color: var(--primary-color);
}
.btn-footer-wrapper {
  display: flex;
}
.horizontal-menu-parent {
  width: 100%;
  height: 47px;
  overflow: hidden;
  position: relative;
}
.horizontal-menu-child {
  position: absolute;
  top: 0;
  overflow: hidden;
  left: 0;
  height: 32px;
  bottom: 0px;
  width: 100%;
}
.horizontal-menu-parent .react-horizontal-scrolling-menu--item button {
  font-weight: 700;
  font-size: 12px;
  margin: 0px 2px;
  border-radius: 3px;
}
.horizental-btn {
  width: 44px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  position: absolute;
  right: 0px;
  cursor: pointer;
}
.horizental-btn svg {
  position: relative;
}
.horizental-btn.right {
  right: 0px;
}
.horizental-btn.left {
  left: 0px;
  right: inherit;
}
.react-horizontal-scrolling-menu--scroll-container {
  margin: 0px 36px;
}
.horizental-btn:before {
  right: 0px;
  background: linear-gradient(to left, rgb(252, 253, 253) 33%, rgba(251, 252, 252, 0));
  position: absolute;
  width: 64px;
  height: 100%;
  content: "";
  pointer-events: none;
}
.horizental-btn.left:before {
  left: 0px;
  background: linear-gradient(to right, rgb(252, 253, 253) 33%, rgba(251, 252, 252, 0));
}

.date-cell {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(37, 42, 49);
  font-weight: 700;
  height: 38.625px;
  width: 12%;
  font-size: 12px;
  background-color: rgb(245, 247, 249);
}
.right-side .date-cell {
  width: 100%;
}
.date-cells-wrapper {
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
}
.return-date {
  position: absolute;
  right: -85px;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}
.flex-depart {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.price-tabel-title.active {
  box-shadow: rgb(37 42 49 / 12%) 0px 1px 0px 0px;
  padding-bottom: 12px;
}
.flex-depart button {
  padding: 0px 4px;
  margin: 0px 4px;
  background: none;
}
.price-tabel {
  position: relative;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  outline: 0px;
  box-shadow:
    rgb(37 42 49 / 16%) 0px 0px 2px 0px,
    rgb(37 42 49 / 12%) 0px 1px 4px 0px;
  transition: box-shadow 0.15s ease-in-out 0s;
  padding: 16px 12px;
  margin-bottom: 16px;
}
.price-tabel .progress-bar-calendar {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
}
.price-tabel-title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.price-tabel-title svg:last-child {
  margin-left: auto;
}
.price-tabel-title svg {
  width: 16px;
  height: 16px;
  margin-right: 12px;
}
.flex-depart button:hover {
  background: rgb(232, 237, 241);
}
.flex-depart button:first-child svg {
  transform: rotate(90deg);
}
.flex-depart button:nth-child(2) svg {
  transform: rotate(-90deg);
}
.date-calendar-table {
  margin-top: 12px;
  position: relative;
}
.date-calendar-container {
  display: flex;
}
.right-side.date-cells-wrapper {
  display: inline-block;
  width: 12%;
}
.price-cell {
  height: 38.625px;
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  font-size: 12px;
  justify-content: center;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(232, 237, 241);
}
.date-cells-wrapper .price-cell:not(.MuiSkeleton-root):hover {
  background-color: var(--primary-color) !important;
  color: #fff !important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: var(--primary-color) !important;
}
.modal-tilte {
  position: relative;
  z-index: 13;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  box-shadow:
    rgb(37 42 49 / 16%) 0px 2px 2px -2px,
    rgb(37 42 49 / 12%) 0px 4px 4px -4px;
}
.modal-tilte p {
  padding: 14px 12px;
}
.modal-tabel-price {
  position: relative;
  flex: 1 1;
  overflow: scroll;
}
.departure-date-cell {
  position: absolute;
  width: 25%;
  height: 83.5px;
  border: 1px solid rgb(232, 237, 241);
  overflow: hidden;
  display: flex;
  font-size: 12px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  color: rgb(37, 42, 49);
  font-weight: 700;
  background-color: rgb(245, 247, 249);
}
.table-date-cell {
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 83.5px;
}
.table-date-cell-wrapper {
  display: flex;
}
.price-by-dates {
  width: 75%;
}
.return-dates .table-date-cell {
  width: 100%;
  height: 100%;
}
.table-date-cell-wrapper .return-dates .table-date-cell .departure-date-cell {
  width: 100%;
}
.price-date-cell {
  color: rgb(79, 94, 113);
  font-size: 12px;
  font-weight: normal;
  background-color: rgb(255, 255, 255);
  border-bottom: 1px solid rgb(232, 237, 241);
  border-left: 1px solid rgb(232, 237, 241);
}
.table-date-cell-wrapper .table-date-cell .departure-date-cell {
  width: 33.3%;
}
.return-dates {
  width: 25%;
}
.tabel-price .modal-footer {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0px;
  padding: 0px 12px;
  background-color: rgb(255, 255, 255);
  box-shadow:
    rgb(37 42 49 / 12%) 0px -2px 4px 0px,
    rgb(37 42 49 / 16%) 0px 0px 2px 0px;
  z-index: 7;
}
.search-form-wrapper .card-above {
  display: none;
}
.skeleton-search-left {
  width: 60%;
}
.skeleton-search-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
div.skeleton-search-right span:first-child {
  margin: auto;
}
.pop-up-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0px;
  outline: 0;
  box-shadow: none;
  background-color: #fff;
  border-radius: 10px;
  padding: 24px;
}
.footer-popup {
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
}
.footer-popup button {
  margin-left: 8px;
}

.item-flex .pass-users img {
  margin: 0px 8px;
}
.img-avatar.pass-users {
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  margin-left: 0px;
}

.logos-collapse {
  width: 16px;
  height: 16px;
}
@media (min-width: 992px) {
  .pop-up-modal {
    width: 400px;
  }
  .search-results-view {
    padding: 0px 40px;
  }
  .search-form-filter {
    display: block;
    /* position: fixed;
    bottom: -30px; */
  }
  .sortBy-quality {
    display: flex;
  }
}

@media (min-width: 576px) {
  .search-results-list {
    padding: 24px;
  }
  .search-form-wrapper .search-form {
    padding: 12px 40px 16px;
    position: sticky;
    top: 0px;
    z-index: 900;
    background: #fff;
  }
}

@media (min-width: 768px) {
  .mobile-show {
    display: none;
  }
}

.departure-wrapper-mobile {
  position: relative;
  height: 40px;
}
.return-wrapper-mobile .return-date {
  right: 15px;
  top: -54px;
}

.return-wrapper-mobile .return-date button {
  margin-top: 15px;
}
.return-wrapper-mobile .return-date p {
  position: absolute;
  right: -4px;
  top: -23px;
  width: 71px;
}

.departure-wrapper-mobile .date-cells-wrapper {
  position: absolute;
  height: 100%;
}
.departure-wrapper-mobile .date-cells-wrapper .date-cell {
  color: rgb(37, 42, 49);
  font-weight: 700;
  font-size: 12px;
  height: 100%;
  width: 85px;
}

.tabel-price.full-size-modal .right-side.date-cells-wrapper {
  width: 85px;
}
.calendar-container-mobile {
  position: relative;
  height: 68vh;
}
.tabel-price.full-size-modal .price-cell {
  font-weight: 700;
  font-size: 12px;
  height: 55px;
  width: 100%;
}
.tabel-price.full-size-modal .date-calendar-container {
  position: absolute;
}
.return-wrapper-mobile .date-cell {
  color: rgb(37, 42, 49);
  font-weight: 700;
  font-size: 12px;
  width: 100%;
  height: 55.5px;
}
.return-wrapper-mobile {
  position: relative;
  right: 0px;
  top: 82px;
}
#flight-logo-calendar {
  width: 16px;
  margin-right: 4px;
}
@media (min-width: 768px) and (max-width: 850px) {
  .price-cell,
  .date-cell {
    font-size: 10px;
  }
}
@media (max-width: 768px) {
  .desktop-show {
    display: none;
  }
  .search-form-wrapper .search-form {
    margin-top: 24px;
  }
}
