.badge {
  flex: 0 0 auto;
  box-sizing: border-box;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  min-height: 24px;
  display: flex;
  line-height: 14px;
  width: fit-content;
  font-size: 12px;
  font-weight: 500;
  background: rgb(245, 247, 249);
  color: rgb(37, 42, 49);
  margin: 0px 4px 4px 0px;
  border-radius: 12px;
  border: 1px solid rgb(232, 237, 241);
  padding: 5px 8px;
}
.badge svg {
  width: 16px;
  height: 16px;
  fill: rgb(37, 42, 49);
}
.badge.teal {
  background: rgb(248, 228, 220);
  border: 1px solid #dfa58d;
}
.badge.teal span {
  color: var(--black-color);
}
.results-card-badges .badges {
  margin: 0px;
}
.badge.icon {
  border-radius: 50%;
  padding: 0px;
  width: 24px;
}
.remove-btn {
  padding: 0px 4px;
  cursor: pointer;
}
.badge span {
  font-size: 12px;
}

.badge.blue {
  background: rgba(97, 106, 171, 0.2);
  border: 1px solid #616aab;
}

.badge.green {
  background: rgba(97, 171, 141, 0.2);
  border: 1px solid #68b379;
}

.badge.blink {
  opacity: 1;
  animation: fade 1.6s linear infinite;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .badge-float {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
