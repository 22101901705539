.search-form.job h1 {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 24px;
}
.search-results-view-jobs {
  padding: 24px 16px;
  background-color: rgb(245, 247, 249);
}
.search-results-view-jobs .card-card {
  margin-top: 24px;
  max-width: 930px;
}
.search-results-view-jobs .card-card p,
.search-results-view-jobs .card-card li {
  font-size: 16px;
  margin-bottom: 6px;
}
.search-results-view-jobs .card-card.grid {
  gap: 16px;
  display: grid;
}
.search-results-view-jobs .card-card.grid button {
  margin: 0px 0px 0px auto;
}
.job-social-media {
  display: none;
}
.note-font {
  font-style: italic;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .btns-wrapper {
    padding: 12px 16px 16px;
    position: relative;
  }
  .main-booking-btn.bigBtn {
    height: 44px;
  }
  .search-results-view-jobs .card-card.grid button {
    grid-column: 2;
  }
  .search-results-view-jobs {
    padding: 24px 50px;
  }
  .job-social-media {
    right: 50px;
    top: 300px;
    position: fixed;
    z-index: 99;
    display: block;
  }
  .mobile-content {
    display: none;
  }
  /* .overview .journey-details .itinerary-trip {
    width: 50%;
  } */
}
