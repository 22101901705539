.feature img {
  margin-bottom: 1rem;
}
.feature h3 {
  color: var(--black-color);
}
.feature h3 {
  font-size: 22px;
  color: var(--black-color);
}
.feature p {
  font-size: 14px;
}
button.global-btn {
  background: #fdb99d;
  border-radius: 3px;
  padding: 8px 13px;
  display: inline-block;
  width: auto;
  color: var(--black-color);
}
