.visa-page {
  background-color: #f8f8f8;
}
/* .visa-page  .card-card p.bold{
  display:inline-block;
} */
.MuiTooltip-tooltip {
  background-color: var(--black-color) !important;
  font-size: 13px !important;
  font-weight: 400;
}
.MuiTooltip-arrow {
  color: var(--black-color) !important;
}
.gird-inputs-container {
  display: grid;
  gap: 16px;
}
.gird-inputs-container .select-labels-container {
  margin-bottom: 8px;
}
