.suggest-card {
  min-height: 250px;
  height: 250px;
  /* width: 25%; */
  padding-right: 16px;
  padding-bottom: 16px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.overlay-image,
.overlay-image-hover {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 45%,
    rgba(0, 0, 0, 0.6) 100%
  );
}
.suggest-card:hover .overlay-image-hover {
  opacity: 1;
}
.suggest-card:hover span.top_heading {
  border-bottom: 1px solid rgb(255, 255, 255);
}

.overlay-image-hover {
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition-duration: 0.3s;
  transition-property: opacity;
}
.suggest-card img {
  position: absolute;
  inset: 0px;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
}

.suggest-card .content {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.suggest-card .content span.top_heading {
  font-size: 12px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  line-height: 16px;
  text-align: left;
  width: max-content;
}
.suggest-card .content span.hide-p {
  display: none;
  line-height: 22px;
}
.suggest-card .content span.hide-p:nth-of-type(2) {
  margin-bottom: 18px;
}
.suggest-card:hover .content span.hide-p {
  display: block;
  border: none;
}
.suggest-card:hover h3 .move-p {
  transform: translateY(-1px);
}
.suggest-card h3 div.move-p {
  margin-bottom: 5px;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  transition-property: transform;
}
.suggest-card .line {
  position: absolute;
  height: 1px;
  background-color: #fff;
  bottom: 40px;
  left: 12px;
  right: 12px;
  transform: scaleX(0);
  transform-origin: 0px center;
  transition-timing-function: ease-out;
  transition-duration: 0.3s;
  transition-property: transform;
}
.suggest-card:hover .line {
  transform: scaleX(1);
}
.suggest-card .content h3 {
  font-size: 22px;
  font-weight: 500;
  color: rgb(255, 255, 255);
  line-height: 28px;
  margin: 0px 0px 9px;
  transform: translateY(0px);
}
.suggest-card .content span {
  font-size: 14px;
  font-weight: 700;
  color: rgb(255, 255, 255);
  line-height: 20px;
  text-align: left;
  display: block;
}
.suggest-card .content svg {
  color: #fff;
  width: 24px;
  fill: #fff;
}
