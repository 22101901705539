.input-wapper .input-class {
  border: none;
  width: 100%;
}
.react-tel-input .flag-dropdown {
  border: none;
  background: none;
}
.input-wapper.phone-input {
  overflow: visible;
}
.react-tel-input .country-list {
  max-height: 169px;
}

.react-tel-input .form-control {
  border: none;
}
