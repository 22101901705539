button.Mui-selected {
  color: var(--primary-color) !important;
}
.MuiTab-root {
  font-weight: 700 !important;
  color: var(--black) !important;
}
.MuiTabs-indicator {
  background-color: var(--primary-color) !important;
}
