.modal-bottom .MuiDrawer-paperAnchorBottom {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.full-size-modal .MuiDrawer-paperAnchorBottom {
  border-radius: 0px;
}
.modal-bottom .MuiDrawer-paperAnchorBottom .MuiListItemIcon-root {
  min-width: 24px;
}

/* .modal-bottom .MuiDrawer-paperAnchorBottom ul {
  max-height: 77vh;
  overflow-y: auto;
} */
.modal-bottom .MuiDrawer-paperAnchorBottom svg {
  display: inline-block;
  /* width: 24px;
  height: 24px; */
  flex-shrink: 0;
  vertical-align: middle;
  fill: currentcolor;
  color: rgb(79, 94, 113);
  margin-right: 12px;
}
.modal-bottom .MuiDrawer-paperAnchorBottom button svg {
  margin-right: 0px;
}
/* .modal-bottom .item-flex {
  padding: 0px 16px;
} */
.btns-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 16px;
  position: fixed;
  bottom: 0px;
  width: 100%;
  align-items: center;
  padding: 23px;
  background: #fff;
}
.btns-wrapper button {
  width: 100%;
}
.btns-wrapper button:first-child {
  margin-right: 16px;
}
.title-list {
  font-size: 16px;
  font-weight: 500;
  margin: 16px;
}
