.reservation {
  background-color: #f8f8f8;
}
.reservation-content {
  box-sizing: border-box;
  margin: 0px auto;
  width: 100%;
  padding: 16px;
  max-width: 1200px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  gap: 16px;
}
.reversation-sideBar-wrapper {
  display: none;
}
.reservation-column-stepper {
  grid-column: 1 / -1;
}
.heading-title {
  font-size: 28px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 16px;
}
.heading-title svg {
  width: 32px;
  height: 32px;
}
.no-expand hr {
  display: none;
}
.heading-title span {
  line-height: 44px;
  font-size: 40px;
  margin: 0px 8px;
}
.note-font {
  font-style: italic;
}
.journey-details {
  display: flex;
  flex-wrap: wrap;
}
.itinerary-trip {
  width: 100%;
  margin-bottom: 16px;
}

div.itinerary-trip:nth-child(1) {
  padding-right: 25px;
}
.itinerary-trip-title {
  padding-top: 12px;
}
.itinerary-depart {
  font-weight: 700;
  text-transform: uppercase;
  color: #57626c;
  font-size: 12px;
}
.arrival-time {
  margin-left: 28px;
  margin-top: 14px;
}
.itinerary-depart-time {
  padding-left: 8px;
  color: #57626c;
  font-size: 12px;
}
.reversation-bill-item,
.reversation-bill-box-finel-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
}
.reversation-bill-box-finel-item p:nth-child(2) {
  font-size: 22px;
  font-weight: 700;
}

.reservation-column-stepper .Mui-completed,
.Mui-active {
  color: var(--primary-color) !important;
  cursor: pointer;
}

.reservation-column-stepper .MuiStepLabel-label {
  font-size: 12px;
  color: var(--dark-color) !important;
}
svg.Mui-active {
  box-shadow: rgb(241 94 34 / 20%) 0px 0px 0px 6px;
  border-radius: 50%;
}
.reservation-column-stepper .Mui-completed:hover svg {
  box-shadow: rgb(241 94 34 / 20%) 0px 0px 0px 6px;
  border-radius: 50%;
}
.reservation-column-stepper .Mui-completed:hover .MuiStepLabel-label {
  color: rgb(37, 42, 49) !important;
  text-decoration: underline;
}
.MuiStepLabel-label.Mui-active {
  font-weight: 500;
  color: rgb(37, 42, 49) !important;
}
.itinerary-timeline {
  position: relative;
  max-width: 380px;
  margin-bottom: 16px;
}
.itinerary-timeline:before {
  content: "";
  position: absolute;
  background: url("https://www.kiwi.com/images/general/itinerary-border.svg") left top repeat-y;
  width: 2px;
  left: 8px;
  top: 0;
  bottom: 0;
}
.itinerary-date {
  display: flex;
  padding-left: 28px;
  align-items: flex-start;
  justify-content: space-between;
}
.itinerary-icon-wrapper svg {
  position: absolute;
  left: 1px;
  z-index: 99;
  background-color: #fff;
  width: 16px;
  height: 16px;
}

.itinerary-flight {
  cursor: pointer;
  position: relative;
  padding-left: 27px;
  margin: 6px 3px 6px 0;
}
.itinerary-milestone-icon {
  position: absolute;
  background: #fff;
  left: 1px;
  padding: 3px 0px;
  margin-top: 11px;
}
.itinerary-milestone-icon svg {
  fill: #adb9c5;
  width: 16px;
  height: 16px;
  transform: rotate(180deg);
}
.free-check {
  border-top: 1px solid rgb(239, 242, 245);
  padding-top: 14px;
  padding-bottom: 20px;
}
.card-card input {
  padding: 0px 12px;
}
.form-inputs .input-group-container {
  width: 100%;
  margin-bottom: 16px;
}
.card-card {
  position: relative;
  border: 1px solid rgb(239, 242, 245);
  background: rgb(255, 255, 255);
  padding: 16px;
  border-radius: 3px;
  /* overflow: hidden; */
  margin-bottom: 32px;
}

.select-group {
  width: 100%;
}
.left-group .select-labels-container:nth-child(1) {
  width: 60%;
  margin-right: 16px;
}
.left-group .select-labels-container:last-child {
  width: 40%;
}
.cabin-box-container.desktop {
  display: none;
}
.cabin-box-container {
  position: relative;
  scroll-snap-align: start;
  width: 100%;
  background-color: rgb(255, 255, 255);
  padding: 12px;
  margin-top: 2px;
  margin-right: 16px;
  margin-bottom: 32px;
  box-shadow:
    rgb(37 42 49 / 16%) 0px 0px 2px 0px,
    rgb(37 42 49 / 12%) 0px 1px 4px 0px;
  border-radius: 6px;
  overflow: visible;
  transition: box-shadow 0.3s ease 0s;
  cursor: pointer;
  box-sizing: border-box;
}
.cabin-box-container.active {
  border: 2px solid var(--primary-color);
}
.cabin-box {
  display: flex;
  flex-flow: column nowrap;
  -webkit-box-flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  -webkit-box-pack: start;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
}

.checked-bages .cabin-box-container:first-child {
  width: 50%;
}
.checked-bages {
  display: flex;
}
.cabin-box img {
  max-height: 120px;
  margin: 8px;
  margin-top: 16px;
}
button.main-booking-btn.bigBtn.booking {
  font-size: 16px;
  height: 52px;
  padding: 0px 16px 0px 28px;
  margin: 0px 0px 90px auto;
  border-radius: 3px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
button.booking svg {
  transform: rotate(-90deg);
  margin-left: 12px;
  fill: #fff;
}
.reversation-bill {
  position: sticky;
  top: 65px;
}
.location {
  padding-left: 8px;
  color: #7c8b99;
}

.itinerary-milestone .flight-time-duration {
  position: absolute;
  font-size: 11px;
  color: #7c8b99;
  right: 10px;
  z-index: 999;
  top: 39px;
  background: #fff;
}
.collapse-container {
  border: 1px solid #e9eef2;
  border-radius: 3px;
  padding: 6px 10px 6px 8px;
  max-height: 50px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
}
.itinerary-collapse {
  position: relative;
}
.itinerary-collapse:before {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 6px 0;
  border-color: transparent #e9eef2 transparent transparent;
  margin-top: -7px;
  content: "";
  position: absolute;
  right: 100%;
  top: 23px;
}
.flight-airlines-details {
  margin-top: 8px;
}
.collapse-container.collapsed {
  max-height: 500px;
  transition: max-height 0.5s ease-in;
}

.cabin-box-container.mobile svg {
  margin-right: 8px;
}
.box-fix {
  position: fixed;
  bottom: 12px;
  width: auto;
  height: auto;
  padding: 10px 16px;
  border-radius: 3px;
  right: 12px;
  background: var(--primary-color);
  cursor: pointer;
  transition: height 1s ease-out;

  height: 47px;
  width: 120px;
  z-index: 1999;
}
.box-fix div {
  display: none !important;
}
.box-fix.expand {
  width: auto;
  height: auto;
  left: 12px;
  transition: height 1s ease-in;
}
.box-fix.expand div {
  display: flex !important;
}
.box-fix:after {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  top: 4px;
  left: 4px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
}
.box-fix p {
  color: #fff;
  font-weight: bold;
}
.box-fix p.box-price {
  font-size: 20px;
  position: absolute;
  right: 12px;
  bottom: 6px;
}

.seat-wapper {
  position: relative;
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
}

.seat-wapper svg {
  width: 18px;
  height: 18px;
  fill: none;
}
.seat-container {
  display: inline-flex;
  flex-flow: column nowrap;
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-shrink: 0;
  -webkit-box-pack: start;
  place-content: center flex-start;
  -webkit-box-align: center;
  align-items: center;
  height: 32px;
  padding: 0px 4px;
  position: relative;
  z-index: 7;
}
.left-wing {
  position: absolute;
  left: -100px;
  background-color: var(--primary-color);
  width: 120px;
}
.right-wing {
  position: absolute;
  right: -100px;
  background-color: var(--primary-color);
  width: 120px;
}
.right-wing:after {
  content: "";
  position: absolute;
  top: 0px;
  width: 0px;
  background: #fff;
  height: 0px;
  border-right: 120px solid transparent;
  border-bottom: 300px solid var(--primary-color);
}
.left-wing:after {
  content: "";
  position: absolute;
  top: 0px;
  width: 0px;
  background: #fff;
  height: 0px;
  border-left: 120px solid transparent;
  border-bottom: 300px solid var(--primary-color);
}
.seat-price {
  font-size: 12px;
  color: rgb(79, 94, 113);
}
.seat-container p {
  font-size: 8px;
  color: rgb(79, 94, 113);
  position: absolute;
  z-index: 105;
  top: 5px;
  font-weight: 600;
}
.seat-booking-wrapper svg path {
  fill: var(--primary-color);
}
.seat-booking-wrapper.light svg path {
  fill: var(--primary-color);
  opacity: 0.2;
}
.seat-container:hover {
  cursor: pointer;
}
.seat-container:hover svg path,
.seat-container.active svg path {
  fill: var(--primary-color);
}
.seat-container:hover p,
.seat-container.active p {
  color: #fff;
}
.light svg path {
  fill: rgb(192, 232, 228);
}
.gray svg path {
  fill: rgb(214, 222, 230);
}
.bigBtn.booking.back svg {
  fill: var(--black-color);
  transform: rotate(90deg);
  margin-right: 12px;
}
button.main-booking-btn.bigBtn.booking.back {
  padding: 0px 28px 0px 16px;
}
.card-card img.seat-img {
  display: none;
}
.small-svg {
  width: 16px;
  height: 16px;
}
.sticky-badges {
  position: sticky;
  top: 0px;
  background: #fff;
  z-index: 9;
  padding: 8px;
}
.seat-booking-wrapper svg {
  width: 16px;
  height: 16px;
}
.journey-nights-border {
  bottom: 0;
  right: 0;
  left: 0;
  height: 1px;
  background: url("https://www.kiwi.com/images/general/TripInfo-border.svg");
  opacity: 0.7;
  width: 125px;
  margin-right: 16px;
}
.itinerary-trip-wrapper {
  width: 100%;
}
.clickable {
  cursor: pointer;
}
.teal.clickable.activeChosen {
  opacity: 1;
}
.outlined-buttons button {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  background-color: #fff;
  margin: 8px 0px;
}
.outlined-buttons button:hover {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  color: #fff !important;
}
.teal.clickable.inactiveChosen {
  opacity: 0.6;
}
@media (max-width: 575px) {
  form .card-card {
    margin-right: -16px;
    margin-left: -16px;
    width: auto;
  }
  .left-wing {
    left: -120px;
  }

  .seats-container .MuiSkeleton-root {
    width: 18px !important;
    height: 34px !important;
  }
  /* .left-wing:after {
    border-left: 50px solid transparent;
  } */
  .right-wing {
    right: -120px;
  }
  /* .right-wing:after {
    border-right: 50px solid transparent;
  } */
}
@media (min-width: 992px) {
  .journey-details {
    flex-wrap: nowrap;
  }
  .overview .journey-details,
  .booking-info .journey-details {
    flex-wrap: wrap;
  }
  .reservation-content {
    padding: 24px;
    column-gap: 24px;
  }
  .heading-title {
    font-size: 40px;
    padding-top: 32px;
  }
  .form-inputs .input-group-container:nth-child(1) {
    margin-right: 24px;
  }

  .res-mr {
    margin-right: 24px;
  }
  div.itinerary-trip-wrapper:nth-child(even) {
    box-shadow: -1px 0 0 0 #e9eef2;
    padding-left: 12px;
  }
  div.itinerary-trip-wrapper:nth-child(odd) {
    padding-right: 12px;
  }
}
@media (max-width: 768px) {
  .seat-price {
    margin-top: 2px;
    white-space: nowrap;
    font-size: 6px;
    color: rgb(79, 94, 113);
    position: absolute;
    bottom: 0px;
  }
  .card-card .MuiTab-root {
    width: 50%;
    padding: 12px 12px;
  }
  .card-card .MuiTabs-flexContainer {
    flex-wrap: wrap;
  }
  .card-card .MuiTabs-indicator {
    display: none;
  }
  .card-card .Mui-selected {
    border-bottom: 3px solid var(--primary-color);
  }
}
@media (min-width: 768px) {
  .itinerary-trip-wrapper {
    width: 50%;
  }
  .seat-wapper svg,
  .seat-wapper {
    width: 38px;
    height: 38px;
  }
  .seat-container {
    padding: 0px 6px;
    height: 70px;
  }
  .seat-container p {
    font-size: 12px;
    top: 9px;
  }
  .card-card img.seat-img {
    display: block;
  }

  .box-fix,
  .cabin-box-container.mobile {
    display: none;
  }
  button.main-booking-btn.bigBtn.booking {
    width: auto;
  }
  button.main-booking-btn.bigBtn.booking.back {
    margin: 0px auto 90px 0px;
    flex-direction: row-reverse;
  }

  .cabin-box-container.desktop {
    display: block;
  }

  .reservation-content {
    grid-template-columns: 7fr minmax(272px, 3fr);
  }
  .reversation-sideBar-wrapper {
    display: block;
    position: relative;
    /* top: 110px;
    padding-top: 10px; */
    margin-bottom: 220px;
  }
  .reversation-bill-box-items {
    padding: 10px 25px 2px;
  }
  .reversation-bill-box-finel-item {
    padding: 0 23px 4px;
    margin-top: 5px;
  }
  .booking-info .itinerary-trip-wrapper {
    width: 50%;
  }
}
@media (min-width: 567px) {
  .card-card {
    padding: 24px;
  }
}

@media (min-width: 400px) {
  .size.gray {
    margin-left: 30px;
  }
}

.modal-scanner {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  top: 0px;
  right: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ocrloader {
  width: 350px;
  height: 250px;
  margin: 20px;
  outline-offset: 10px;
  position: relative;
  overflow: hidden;
  /* position: absolute;
  left: 40%;
  top: 40%; */
}

.ocrloader span::before {
  content: "";
  position: absolute;
  top: 5%;
  bottom: 0;
  left: 4%;
  width: 10px;
  height: 90%;
  background: var(--primary-color);
  box-shadow: 0 0 50px 10px var(--primary-color);
  clip-path: inset(0);
  animation:
    x 1s ease-in-out infinite alternate,
    y 1s ease-in-out infinite;
}

.ocrloader p::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--primary-color);
  position: relative;
  right: 4px;
}

.ocrloader p {
  color: var(--primary-color);
  position: absolute;
  bottom: -30px;
  left: 38%;
  font-size: 16px;
  font-weight: 600;
  animation: blinker 1s linear infinite;
  font-family: sans-serif;
  text-transform: uppercase;
}

.ocrloader:before,
.ocrloader:after,
.ocrloader em:after,
.ocrloader em:before {
  border-color: var(--primary-color);
  content: "";
  position: absolute;
  width: 45px;
  height: 46px;
  border-style: solid;
  border-width: 0px;
}

.ocrloader:before {
  left: 0;
  top: 0;
  border-left-width: 5px;
  border-top-width: 5px;
  border-radius: 5px 0 0 0;
}

.ocrloader:after {
  right: 0;
  top: 0;
  border-right-width: 5px;
  border-top-width: 5px;
  border-radius: 0 5px 0 0;
}
.ocrloader em:before {
  left: 0;
  bottom: 0;
  border-left-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 0 5px;
}
.ocrloader em:after {
  right: 0;
  bottom: 0;
  border-right-width: 5px;
  border-bottom-width: 5px;
  border-radius: 0 0 5px 0;
}

@keyframes move {
  0%,
  100% {
    transform: translateY(190px);
  }
  50% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(160px);
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes x {
  to {
    transform: translateX(-100%);
    left: 100%;
  }
}

@keyframes y {
  33% {
    clip-path: inset(0 0 0 -100px);
  }
  50% {
    clip-path: inset(0 0 0 0);
  }
  83% {
    clip-path: inset(0 -100px 0 0);
  }
}
