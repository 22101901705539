.container-search {
  position: absolute;
  width: auto;
  min-width: 300px;
  top: -12px;
  left: -18px;
  right: -12px;
  z-index: 101;
  background: rgb(255, 255, 255);
  border-radius: 3px;
  box-shadow:
    rgb(37 42 49 / 16%) 0px 4px 8px 0px,
    rgb(37 42 49 / 24%) 0px 8px 24px 0px;
  padding: 10px 0px;
}

.container-search.calendar-container {
  left: -110px;
}
.container-search .input-wapper {
  margin: 8px 16px;
}
p.container-search-title {
  color: var(--black-color);
  margin: 15px;
}
.box-plus {
  background-color: #fbbfa7;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 23px;
  color: #fff;
  border-radius: 3px;
}
